import _slicedToArray from "/workdir/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/slicedToArray.js";
import _defineProperty from "/workdir/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/defineProperty.js";

var _experimentsMap;

var commonExperiments = ["search_category_filters_apr23", "onepass_migration_popup_may_23", "web_tactical_category_page_nov_23", "adults_only_shop_menu_feb_24", "constructor_autocomplete_web_may_24", "constructor_autocomplete_web_nz_oct_24", "promotion_visibility_web_aug_24"];
export var experimentsMap = (_experimentsMap = {}, _defineProperty(_experimentsMap, "buy-now-app", ["buy_now_hide_category_nav", "buy_now_checkout_experiment_phase_1"].concat(commonExperiments)), _defineProperty(_experimentsMap, "header-app", [].concat(commonExperiments)), _defineProperty(_experimentsMap, "privacy-app", [].concat(commonExperiments)), _defineProperty(_experimentsMap, "product-app", ["web_xmas_widget_nov_22", "product_card_redesign_jul23", "incompatible_electronic_recommendations_june_22", "personalised_recommender_title_july_2022", "recently_viewed_recommender_title_july_2022", "similar_product_recommendation_experiment", "personalised_recommender_on_product_page_may_22", "retail_media_product_page_recommender_oct_23", "retail_media_product_page_banner_feb_24"].concat(commonExperiments)), _defineProperty(_experimentsMap, "search-app", ["retail_media_search_results_top_oct_23", "retail_media_search_banner_jan_24", "retail_media_search_banner_bottom_feb_24"].concat(commonExperiments)), _defineProperty(_experimentsMap, "shopfront-app", ["personalised_recommender_title_july_2022", "club_shop_recommender_components_jun21", "recently_viewed_recommender_title_july_2022", "onepass_hero_deal_revamp_nov_22", "onepass_reward_sept22", "onepass_shop_toggle_may24", "web_cart_seasonal_upsell_sep_23", "my_orders_ui_feb_24", "account_security_page_mar24", "retail_media_search_results_top_oct_23", "step_up_challenge_web_jul_24", "retail_media_product_page_banner_feb_24", "retail_media_search_banner_jan_24", "retail_media_search_banner_bottom_feb_24", "retail_media_product_page_banner_feb_24", "cart_saving_web_aug_24"].concat(commonExperiments)), _defineProperty(_experimentsMap, "fallback", [].concat(commonExperiments)), _experimentsMap);
var experimentKeys = Object.entries(experimentsMap).flatMap(function (_ref) {
  var _ref2 = _slicedToArray(_ref, 2),
      _key = _ref2[0],
      val = _ref2[1];

  return val;
});
export var appKeys = Object.keys(experimentsMap);